import {
  getAllItems,
  getComboOffers,
  getItemBySubCategory,
  getItemOffers,
  getMenuCategoryAndSubCat,
  getMenuItemsBySubCategory,
  getRestaurantCategoryList,
  getSubCategoryByCategory,
} from "../../api/menuDetails";
import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../../App.css";
import {
  Accordion,
  Card,
  Container,
  Dropdown,
  Nav,
  Navbar,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../images/CP logo.png";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import ItemCard from "../Home/ItemCard";
import ComboItemCard from "../Home/ComboItemCard";
import OfferItemCard from "../Home/OfferItemCard";
import ItemModal from "../Home/ItemModal";

const MenuItems = () => {
  const { id } = useParams();
  const [category, setCategory] = useState();
  const [menuId, setMenuId] = useState();
  const [subCategory, setSubCategory] = useState();
  const [items, setItems] = useState();

  const [comboList, setComboList] = useState();

  const selectedRestaurant = 98;
  const getRestaurantCategory = async () => {
    const response = await getMenuCategoryAndSubCat(selectedRestaurant);
    if (response.data[0]?.categories) {
      setMenuId(response.data[0]?.menuId);
      setCategory(JSON.parse(response.data[0]?.categories));
    }
    // setActiveTab(response?.data[0]?.id)
    return response?.data;
  };
  //
  useEffect(() => {
    setActiveTab(id.toString());
    getRestaurantsubCategory(id);
  }, [id, category]);

  const getRestaurantsubCategory = async (id) => {
    const sub = category?.find(
      (el) => parseInt(el.categoryId, 10) === parseInt(id, 10)
    );
    // test
    if (sub) {
      sub?.subCategories?.sort(
        (a, b) => a.subCategoryorderNo - b.subCategoryorderNo
      );
      setSubCategory(sub?.subCategories);
      setActiveSubCategoryTab(sub?.subCategories[0]?.subCategoryId);

      getItems(sub?.subCategories[0]?.subCategoryId);
    }
    return sub;
  };

  const getItems = async (subId) => {
    if (subId) {
      const response = await getMenuItemsBySubCategory({
        restaurantId: selectedRestaurant,
        subCategoryId: subId,
        menuId: menuId,
      });
      setItems(response?.data);
      return response?.data;
    }
  };

  const [itemOfferList, setItemOfferLIst] = useState();
  const getItemOffersList = async () => {
    const response = await getItemOffers(selectedRestaurant);
    setItemOfferLIst(response?.data);

    return response?.data;
  };

  const getComboOffersList = async () => {
    const response = await getComboOffers(selectedRestaurant);
    // setItems();
    setComboList(response?.data);
    return response?.data;
  };

  useEffect(() => {
    getRestaurantCategory();
    getItemOffersList();
    getComboOffersList();
  }, []);
  const [isMobile, setIsMobile] = useState(false);
  const [activeTab, setActiveTab] = useState(""); // Default active tab

  useEffect(() => {
    const handleResize = () => {
      // Check if the screen is mobile (width less than 768px)
      setIsMobile(window.innerWidth < 500);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check screen size on initial load

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Handling tab change for both Tabs and Dropdown
  const handleTabSelect = (tabKey) => {
    if (tabKey === "head") {
      navigate("/menu"); // Change to your desired route
    } else {
      setActiveTab(tabKey);
    }
  };

  const [activeSubCategoryTab, setActiveSubCategoryTab] = useState("");

  const handleSubCategoryTabSelect = (tabKey) => {
    setActiveSubCategoryTab(tabKey);
  };

  useEffect(() => {
    getRestaurantsubCategory(activeTab);
  }, [activeTab]);

  useEffect(() => {
    setItems([]);
    getItems(activeSubCategoryTab);
  }, [activeSubCategoryTab]);

  const navigate = useNavigate();

  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      let sections = document.querySelectorAll(".categ-div");

      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top >= 0 && rect.top <= 300) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const title = (title, subTitle, className) => {
    return (
      <div className={className}>
        {title}
        <span>{subTitle}</span>
      </div>
    );
  };

  const [open, setOpen] = useState(false);
  const [itmData, setItmData] = useState();
  const [itemType, setItemType] = useState();

  const arabicTabContent = (id) => {
    return (
      subCategory && (
        <Tabs
          defaultActiveKey={subCategory[0]?.subCategoryId}
          onSelect={handleSubCategoryTabSelect}
          activeKey={activeSubCategoryTab}
          id="uncontrolled-tab-example"
          className="justify-content-center mb-3"
        >
          {subCategory?.map((el) => (
            <Tab
              eventKey={el.subCategoryId}
              title={title(
                el.subCategoryName,
                el.subCategoryNameOther,
                "d-flex flex-column"
              )}
              style={{ minHeight: "300px" }}
            >
              {/* Item name images */}
              <div className="row">
                {items?.map((data) => (
                  <div className="col-md-4 mb-4">
                    <div
                      className="item-card"
                      onClick={() => {
                        setOpen(true);
                        setItmData(data);
                        setItemType("item");
                      }}
                    >
                      <img
                        src={process.env.REACT_APP_baseUrl + data?.itemImage}
                        alt=""
                      />
                      <div className="d-flex justify-content-between p-3">
                        <div>
                          <h5 className="title">{data?.itemName}</h5>
                          <h5 className="title">{data?.itemNameOther}</h5>
                        </div>
                        {/* <div className="dotted-div"></div> */}
                        <div className="price">{data?.itemPrice}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Tab>
          ))}
        </Tabs>
      )
    );
  };

  const comboOfferTab = (id) => {
    return (
      comboList?.length && (
        <div className="row pt-3">
          {comboList?.map((data) => (
            <div className="col-md-4 mb-4">
              <div
                className="item-card"
                onClick={() => {
                  setOpen(true);
                  setItmData(data);
                  setItemType("combo");
                }}
              >
                <img
                  src={process.env.REACT_APP_baseUrl + data?.comboOfferImgUrl}
                  alt=""
                />
                <h5 className="title text-center">{data?.comboOfferName}</h5>
                <div className="d-flex justify-content-between p-3">
                  <div>
                    {JSON.parse(data.item).map((el, index) => (
                      <>
                        <h5 className="title sub-title">{el?.itemName}</h5>
                        <div className="text-center">
                          {index < JSON.parse(data.item)?.length - 1 ? "+" : ""}
                        </div>
                      </>
                    ))}
                  </div>
                  <div className="align-content-center">
                    <del style={{ color: "#656565" }}>{data?.comboPrice}</del>
                    <div className="price">{data?.comboOfferPrice}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    );
  };

  const itemOfferTab = (id) => {
    return (
      itemOfferList?.length && (
        <div className="row pt-3">
          {itemOfferList?.map((data) => (
            <div className="col-md-4 mb-4">
              <div
                className="item-card"
                onClick={() => {
                  setOpen(true);
                  setItmData(data);
                  setItemType("itemOffer");
                }}
              >
                <img
                  src={process.env.REACT_APP_baseUrl + data?.itemOfferImgUrl}
                  alt=""
                />
                <div className="d-flex justify-content-between p-3">
                  <div>
                    <h5 className="title">{data?.itemOfferName}</h5>
                    <h5 className="title sub-title">
                      ({data?.itemName} - {data?.nameOther})
                    </h5>
                  </div>
                  {/* <div className="dotted-div"></div> */}
                  <div>
                    <del style={{ color: "#656565" }}>{data?.price}</del>
                    <div className="price">{data?.offerPrice}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    );
  };

  const catTitle = () => {
    if (activeTab === "itemOffers") {
      return (
        <div className="d-flex justify-content-between drop-cat-title ">
          Item Offers
        </div>
      );
    } else if (activeTab === "comboOffer") {
      return (
        <div className="d-flex justify-content-between drop-cat-title ">
          Combo Offers
        </div>
      );
    } else {
      const name = category?.find(
        (el) => parseInt(el.categoryId, 10) === parseInt(activeTab, 10)
      );

      return (
        <div className="d-flex justify-content-between drop-cat-title ">
          {name.categoryName}
          <span>{name.categoryNameOther}</span>
        </div>
      );
    }
  };

  const [allItems, setAllItems] = useState();

  const transformData = (data) => {
    const result = {};

    data?.forEach((item) => {
      const { categoryName, subCategoryName, orderNo, ...itemData } = item;

      if (!result[categoryName]) {
        result[categoryName] = { orderNo, subcategories: {} };
      }
      if (!result[categoryName].subcategories[subCategoryName]) {
        result[categoryName].subcategories[subCategoryName] = [];
      }

      result[categoryName].subcategories[subCategoryName].push(itemData);
    });

    return Object.entries(result)
      .sort((a, b) => a[1].orderNo - b[1].orderNo)
      .reduce((acc, [key, value]) => {
        acc[key] = value.subcategories;
        return acc;
      }, {});
  };

  const getAllItem = async () => {
    const response = await getAllItems(selectedRestaurant);
    const categorizedData = transformData(response?.data[0]?.item);

    if (categorizedData) {
      setAllItems(categorizedData);
    }
    return response?.data;
  };

  useEffect(() => {
    getAllItem();
  }, []);

  return (
    <>
      <header>
        <Navbar
          expand="lg"
          //   fixed={`top ${isShrunk ? "reduced-nav" : ""}`}
          className="header-top"
        >
          <Container className="menu-header-container">
            <div className="row">
              <ul className="navbar-nav-footer social-nav-footer social-nav-header mb-2 mb-lg-0">
                <li className="nav-item">
                  <FontAwesomeIcon
                    icon={faFacebook}
                    className="fontAddressIcon"
                  />
                </li>
                <li className="nav-item">
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="fontAddressIcon"
                  />
                </li>
                <li className="nav-item">
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    className="fontAddressIcon"
                  />
                </li>
                <li className="nav-item">
                  <FontAwesomeIcon
                    icon={faTwitter}
                    className="fontAddressIcon"
                  />
                </li>
              </ul>
            </div>
            <Navbar.Brand href="/">
              <img src={logo} alt="" />
            </Navbar.Brand>

            <div>
              <Link
                href="tel:23289777"
                className={`phone-no-item menu-header-phone`}
              >
                <FontAwesomeIcon icon={faPhone} /> 23289777
              </Link>
              <Link
                href="tel:93543747"
                className={`phone-no-item menu-header-phone menu-header-whatsapp`}
              >
                <FontAwesomeIcon icon={faWhatsapp} /> 93543747
              </Link>
            </div>
          </Container>
        </Navbar>
      </header>
      <section
        id="menu"
        className="menu-page-content mb-5"
        style={{ background: "#fff" }}
      >
        <div className="container">
          <div className="row">
            <h2 className="text-center mb-5">Our Menu</h2>
            <div className="tab-container mobile-display">
              {isMobile
                ? category && (
                    <>
                      <div className="mob-category-list navbar sticky-top">
                        <div className="w-100">
                          <ul>
                            {category?.map((item) => (
                              <li>
                                <a
                                  href={`#${item.categoryName}`}
                                  className={
                                    activeSection === item.categoryName
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <div>{item.categoryName}</div>

                                  <span>{item?.categoryNameOther}</span>
                                </a>
                              </li>
                            ))}

                            {itemOfferList?.length ? (
                              <li>
                                <a
                                  href={`#itemOffers`}
                                  className={
                                    activeSection === "itemOffers"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <div>Item Offer</div>
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                            {comboList?.length ? (
                              <li>
                                <a
                                  href={`#comboOffer`}
                                  className={
                                    activeSection === "comboOffer"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <div>Combo Offer</div>
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                          </ul>
                        </div>
                      </div>
                      <div>
                        {allItems &&
                          Object.keys(allItems)?.map((el) => (
                            <div
                              id={el}
                              style={{ marginBottom: "40px" }}
                              className="categ-div"
                            >
                              <h6>{el}</h6>
                              {allItems[el] && (
                                <Accordion
                                  alwaysOpen
                                  defaultActiveKey={
                                    allItems[el]
                                      ? Object.keys(allItems[el]).flatMap(
                                          (sub) => sub
                                        )
                                      : []
                                  }
                                >
                                  {Object.keys(allItems[el])?.map((sub) => (
                                    <div
                                      id={sub}
                                      style={{ marginBottom: "20px" }}
                                    >
                                      <Accordion.Item eventKey={sub}>
                                        <Accordion.Header>
                                          {sub}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          {allItems[el][sub]?.map((itm) => (
                                            <ItemCard item={itm} />
                                          ))}
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    </div>
                                  ))}
                                </Accordion>
                              )}
                            </div>
                          ))}

                        {itemOfferList?.length ? (
                          <div id="itemOffers" className="categ-div">
                            <h6>Item Offers</h6>
                            {itemOfferList?.map((itm) => (
                              <OfferItemCard item={itm} />
                            ))}
                          </div>
                        ) : (
                          ""
                        )}
                        {comboList?.length ? (
                          <div id="comboOffer" className="categ-div">
                            <h6>Combo Offers</h6>
                            {comboList?.map((itm) => (
                              <ComboItemCard item={itm} />
                            ))}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  )
                : category && (
                    <>
                      <Tabs
                        defaultActiveKey={category[0]?.categoryId}
                        onSelect={handleTabSelect}
                        activeKey={activeTab}
                        id="menu-page-catogories"
                        className="mb-2 flex-column nav-category-menu"
                      >
                        <Tab eventKey={"head"} title={"Categories"}>
                          Categories
                        </Tab>
                        {category?.map((el) => (
                          <Tab
                            eventKey={el.categoryId}
                            title={title(
                              el.categoryName,
                              el.categoryNameOther,
                              "d-flex justify-content-between cat-item-title"
                            )}
                          >
                            {/* Item subcategory name */}
                            {arabicTabContent(el.categoryId)}
                          </Tab>
                        ))}
                        {comboList?.length || itemOfferList?.length ? (
                          <Tab eventKey={"head"} title={"Offers"} disabled>
                            Offers
                          </Tab>
                        ) : (
                          ""
                        )}
                        {itemOfferList?.length ? (
                          <Tab
                            eventKey="itemOffers"
                            title={title(
                              "Item Offer",
                              "",
                              "d-flex justify-content-between cat-item-title"
                            )}
                          >
                            {/* Item subcategory name */}
                            {itemOfferTab()}
                          </Tab>
                        ) : (
                          ""
                        )}
                        {comboList?.length ? (
                          <Tab
                            eventKey={"comboOffer"}
                            title={title(
                              "Combo Offer",
                              "",
                              "d-flex justify-content-between cat-item-title"
                            )}
                          >
                            {/* Item subcategory name */}
                            {comboOfferTab()}
                          </Tab>
                        ) : (
                          ""
                        )}
                      </Tabs>
                    </>
                  )}
            </div>
          </div>
        </div>
        <ItemModal
          item={itmData}
          type={itemType}
          show={open}
          handleClose={() => setOpen(false)}
        />
      </section>
      <footer className="pt-0">
        <div className="text-center copyright_section">
          &copy; 2024. All rights reserved
        </div>
      </footer>
    </>
  );
};
export default MenuItems;
