import { useState } from "react";
import ItemModal from "./ItemModal";

const ItemCard = ({ item }) => {
  const [open, setOpen] = useState(false);
  const [itmData, setItmData] = useState();
  return (
    <>
      <div
        className="item-card-mob"
        onClick={() => {
          setOpen(true);
          setItmData(item);
        }}
      >
        <h6>{item.itemName}</h6>
        <div className="d-flex mob-item-details">
          <img src={process.env.REACT_APP_baseUrl + item?.imgUrl} alt="" />
          <div className="description-det d-flex justify-content-between">
            <span className="ar-name">{item.itemNameOther}</span>
            <span className="it-price">{item.price}</span>
          </div>
        </div>
      </div>
      <ItemModal
        item={itmData}
        type={"item"}
        show={open}
        handleClose={() => setOpen(false)}
      />
    </>
  );
};

export default ItemCard;
