import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getComboOffers,
  getItemOffers,
  getMenuCategoryAndSubCat,
} from "../../api/menuDetails";
import { Container, Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import logo from "../../images/CP logo.png";
const Menu = () => {
  const [category, setCategory] = useState();
  const selectedRestaurant = 98;
  const getRestaurantCategory = async () => {
    const response = await getMenuCategoryAndSubCat(selectedRestaurant);
    if (response.data[0]?.categories)
      setCategory(JSON.parse(response.data[0]?.categories));
    return response.data[0]?.categories;
  };
  const [itemOfferList, setItemOfferLIst] = useState();
  const [comboList, setComboList] = useState();
  const getItemOffersList = async () => {
    const response = await getItemOffers(selectedRestaurant);
    setItemOfferLIst(response?.data);

    return response?.data;
  };

  const getComboOffersList = async () => {
    const response = await getComboOffers(selectedRestaurant);
    // setItems();
    setComboList(response?.data);
    return response?.data;
  };

  useEffect(() => {
    getRestaurantCategory();
    getItemOffersList();
    getComboOffersList();
  }, []);

  console.log(category);
  return (
    <>
      <header>
        <Navbar
          expand="lg"
          //   fixed={`top ${isShrunk ? "reduced-nav" : ""}`}
          className="header-top"
        >
          <Container className="menu-header-container">
            <div className="row">
              <ul className="navbar-nav-footer social-nav-footer social-nav-header mb-2 mb-lg-0">
                <li className="nav-item">
                  <FontAwesomeIcon
                    icon={faFacebook}
                    className="fontAddressIcon"
                  />
                </li>
                <li className="nav-item">
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="fontAddressIcon"
                  />
                </li>
                <li className="nav-item">
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    className="fontAddressIcon"
                  />
                </li>
                <li className="nav-item">
                  <FontAwesomeIcon
                    icon={faTwitter}
                    className="fontAddressIcon"
                  />
                </li>
              </ul>
            </div>
            <Navbar.Brand href="/">
              <img src={logo} alt="" />
            </Navbar.Brand>

            <div>
              <Link
                href="tel:23289777"
                className={`phone-no-item menu-header-phone`}
              >
                <FontAwesomeIcon icon={faPhone} /> 23289777
              </Link>
              <Link
                href="tel:93543747"
                className={`phone-no-item menu-header-phone menu-header-whatsapp`}
              >
                <FontAwesomeIcon icon={faWhatsapp} /> 93543747
              </Link>
            </div>
          </Container>
        </Navbar>
      </header>
      <div className="container mb-5 menu-page-content" id="menu-page">
        <h2 className="text-center mb-5">Our Menu</h2>
        <div className="row">
          {category?.map((el) => (
            <div className="col-md-4 mb-4">
              <a
                className="menu-main-card"
                href={`/menu-items/${el.categoryId}`}
              >
                <img
                  src={process.env.REACT_APP_baseUrl + el.categoryImage}
                  alt={el.categoryName}
                />
                <h5 class="menu-card-title text-center">
                  {el.categoryName}
                  <div>{el?.categoryNameOther}</div>
                </h5>
              </a>
            </div>
          ))}
        </div>
      </div>

      <div className="container mb-5 menu-page-content" id="menu-page">
        <h2 className="text-center mb-5">Offers</h2>
        <div className="row">
          {itemOfferList?.length ? (
            <div className="col-md-4 mb-4">
              <a className="menu-main-card" href={`/menu-items/itemOffers`}>
                <img
                  src={
                    process.env.REACT_APP_baseUrl +
                    "uploads/category/1737563683345.webp"
                  }
                  alt={"item Offer"}
                />
                <h5 class="menu-card-title text-center">Item Offer</h5>
              </a>
            </div>
          ) : (
            ""
          )}
          {comboList?.length ? (
            <div className="col-md-4 mb-4">
              <a className="menu-main-card" href={`/menu-items/comboOffer`}>
                <img
                  src={
                    process.env.REACT_APP_baseUrl +
                    "uploads/category/1737563683345.webp"
                  }
                  alt={"Combo Offer"}
                />
                <h5 class="menu-card-title text-center">Combo Offer</h5>
              </a>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <footer className="pt-0">
        <div className="text-center copyright_section">
          &copy; 2024. All rights reserved
        </div>
      </footer>
    </>
  );
};
export default Menu;
