import { Modal } from "react-bootstrap";

const ItemModal = ({ item, type, show, handleClose }) => {
  return (
    item && (
      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      >
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton className="itm-modal-header">
            {/* <Modal.Title>
            {type === "item"
              ? item?.itemName
              : [type === "combo" ? item?.comboOfferName : item?.itemOfferName]}
          </Modal.Title> */}
          </Modal.Header>

          <Modal.Body>
            <div className="itemModal">
              {type === "item" ? (
                <>
                  {" "}
                  <img
                    src={
                      process.env.REACT_APP_baseUrl +
                      (item?.itemImage || item?.imgUrl)
                    }
                    alt=""
                  />
                  <div className="d-flex justify-content-between p-3">
                    <div>
                      <h5 className="title">{item?.itemName}</h5>
                      <h5 className="title">{item?.itemNameOther}</h5>
                    </div>
                    <div className="price">
                      {item?.itemPrice || item?.price}
                    </div>
                  </div>
                </>
              ) : (
                [
                  type === "combo" ? (
                    <>
                      <img
                        src={
                          process.env.REACT_APP_baseUrl + item?.comboOfferImgUrl
                        }
                        alt=""
                      />
                      <h5 className="title text-center">
                        {item?.comboOfferName}
                      </h5>
                      <div className="d-flex justify-content-between p-3">
                        <div>
                          {JSON.parse(item.item).map((el, index) => (
                            <>
                              <h5 className="title sub-title">
                                {el?.itemName}
                              </h5>
                              <div className="text-center">
                                {index < JSON.parse(item.item)?.length - 1
                                  ? "+"
                                  : ""}
                              </div>
                            </>
                          ))}
                        </div>
                        <div className="align-content-center">
                          <del style={{ color: "#656565" }}>
                            {item?.comboPrice}
                          </del>
                          <div className="price">{item?.comboOfferPrice}</div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <img
                        src={
                          process.env.REACT_APP_baseUrl + item?.itemOfferImgUrl
                        }
                        alt=""
                      />
                      <div className="d-flex justify-content-between p-3">
                        <div>
                          <h5 className="title">{item?.itemOfferName}</h5>
                          <h5 className="title sub-title">
                            ({item?.itemName} - {item?.nameOther})
                          </h5>
                        </div>
                        {/* <div className="dotted-div"></div> */}
                        <div>
                          <del style={{ color: "#656565" }}>{item?.price}</del>
                          <div className="price">{item?.offerPrice}</div>
                        </div>
                      </div>
                    </>
                  ),
                ]
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  );
};

export default ItemModal;
